import { MentionData } from '@draft-js-plugins/mention'
import classNames from 'classnames'
import { convertToRaw, EditorState } from 'draft-js'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from 'app/components/form/formField/formFieldComment/formFieldCommentDetail/editComment/EditComment.module.scss'
import {
  WppAvatar,
  WppDivider,
  WppIconClose,
  WppIconDone,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from 'buildingBlocks'
import MentionEditor from 'buildingBlocks/mention/MentionEditor'
import { COMMENT_TEXT_EDITOR_MAX_LENGTH } from 'config/constants'
import IComment from 'interfaces/field/fieldComment/IComment'
import { RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'

interface IFormFieldCommentEditDetailProps {
  inputComment: IComment
  onEdit: (comment: IComment, config: Object, value: string, mentions: string[], cb: Function) => void
  setOpen: Function
  isCommentAllowed: boolean
  mentionOptions: MentionData[]
  selectedComment: IComment | null
  setSelectedComment: Function
  setActiveAction: (value: boolean) => void
}

interface IEdit {
  open: boolean
  disabled: boolean
}

/**
 * Edit field comment
 * @param {object} props
 * @param {IComment} props.inputComment
 * @param {Function} props.onEdit
 * @param {Function} props.setOpen
 * @param {Function} props.setSelectedComment
 * @param {IComment | null} props.selectedComment
 * @param {MentionData[]} props.mentionOptions
 * @param {boolean} props.isCommentAllowed
 * @param {Function} props.setActiveAction
 */
const FormFieldCommentEditDetail: React.FC<IFormFieldCommentEditDetailProps> = ({
  inputComment,
  onEdit,
  mentionOptions,
  isCommentAllowed,
  setOpen,
  selectedComment,
  setSelectedComment,
  setActiveAction,
}: IFormFieldCommentEditDetailProps): React.ReactElement => {
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)

  const { id, issuerFullname, issuerEmail, issuerAvatarUrl, createdAt, value, updatedAt, config } = inputComment
  const [editorState, setEditorState] = useState(() => FieldCommentHelper.getEditorState(value, config))
  const [edit, setEdit] = useState<IEdit>({ open: false, disabled: true })
  const [mentions, setMentions] = useState<string[]>([])

  const initialValue = useMemo(() => {
    return FieldCommentHelper.getEditorState(value, config)
  }, [value, config])

  const onEditComment = () => {
    if (!selectedComment) return
    let userMentions: string[] = FieldCommentHelper.getMentions(convertToRaw(editorState.getCurrentContent()))
    userMentions = mentions.filter((user: string) => userMentions.includes(user))

    onEdit(
      selectedComment,
      convertToRaw(editorState.getCurrentContent()),
      editorState.getCurrentContent().getPlainText(),
      userMentions,
      (error: any) => {
        if (_.isEmpty(error)) {
          setEdit({ open: false, disabled: true })
          setSelectedComment(null)
          setMentions([])
        }
        setActiveAction(!_.isEmpty(error))
      },
    )
  }

  const onCancelComment = () => {
    setEdit({ open: false, disabled: true })
    setSelectedComment(null)
    setEditorState(initialValue)
    setActiveAction(false)
  }

  useEffect(() => {
    if (!_.isEqual(selectedComment?.id, id)) {
      setEdit({ open: false, disabled: true })
      setEditorState(initialValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComment, id])

  useEffect(() => {
    setEditorState(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (!edit) setActiveAction(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit.open])

  const commentedDate = !_.isNull(updatedAt) ? moment.utc(updatedAt).fromNow() : moment.utc(createdAt).fromNow()

  return (
    <div
      className={classNames(styles.commentContainer, { [styles.selectedComment]: _.isEqual(selectedComment?.id, id) })}
    >
      <div className={styles.header}>
        <div className={styles.userInfo}>
          <WppAvatar name={issuerEmail} withTooltip src={issuerAvatarUrl} />
          <WppTypography tag="p" type="s-strong">
            {issuerFullname}
          </WppTypography>
          <WppTypography tag="p" type="s-body">
            {commentedDate}
          </WppTypography>
          {!_.isNull(updatedAt) && (
            <WppTypography className={styles.edited} tag="p" type="xs-body">
              <Trans>comment.text.edited</Trans>
            </WppTypography>
          )}
        </div>
        {isCommentAllowed && edit.open && _.isEqual(selectedComment?.id, id) && (
          <div className={styles.editComment}>
            <WppIconClose className={styles.editComment__icon} onClick={onCancelComment} />
            <WppDivider dir="vertical" />
            <WppIconDone
              className={classNames(styles.editComment__icon, { [styles.disableEdit]: edit.disabled })}
              onClick={() => {
                if (!edit.disabled) onEditComment()
              }}
            />
          </div>
        )}
        {isCommentAllowed && !edit.open && _.isEqual(_.toLower(issuerEmail), _.toLower(appContext.userEmail)) && (
          <div>
            <WppMenuContext
              dropdownConfig={{
                zIndex: 1250,
                onShow: () => {
                  setActiveAction(true)
                },
              }}
            >
              <WppIconMore
                color="var(--wpp-brand-color)"
                className={styles.menuIcon}
                slot="trigger-element"
                direction="horizontal"
                size="m"
              />
              <div>
                <WppListItem
                  data-testid="comment-edit"
                  onWppChangeListItem={() => {
                    setEdit((prevState: IEdit) => ({
                      ...prevState,
                      open: true,
                    }))
                    setSelectedComment(inputComment)
                  }}
                >
                  <p slot="left">
                    <WppIconEdit />
                  </p>
                  <p slot="label">
                    <WppTypography tag="span" type="s-body">
                      <Trans>app.action.edit</Trans>
                    </WppTypography>
                  </p>
                </WppListItem>
                <WppListItem
                  data-testid="comment-delete"
                  onWppChangeListItem={() => {
                    setSelectedComment(inputComment)
                    setOpen(true)
                  }}
                >
                  <p slot="left">
                    <WppIconTrash />
                  </p>
                  <p slot="label">
                    <WppTypography tag="span" type="s-body">
                      <Trans>app.action.delete</Trans>
                    </WppTypography>
                  </p>
                </WppListItem>
              </div>
            </WppMenuContext>
          </div>
        )}
      </div>
      <MentionEditor
        editorState={editorState}
        setEditorState={(updatedEditorState: EditorState) => {
          const currentContent = initialValue.getCurrentContent().getPlainText()
          const newContent = updatedEditorState.getCurrentContent().getPlainText()

          const charCount = FieldCommentHelper.getCharCount(updatedEditorState)
          setEdit((prevState: IEdit) => ({
            ...prevState,
            disabled:
              _.isEqual(currentContent, newContent) ||
              _.isEqual(charCount, 0) ||
              _.gt(charCount, COMMENT_TEXT_EDITOR_MAX_LENGTH),
          }))
          setEditorState(updatedEditorState)
        }}
        readOnly={!(edit.open && _.isEqual(selectedComment?.id, id))}
        mentionOptions={mentionOptions}
        onAddMention={(mention: MentionData) => {
          if (mention?.id) {
            setMentions((arr: string[]) => _.uniq([...arr, _.toString(mention.id)]))
          }
        }}
      />
    </div>
  )
}

export default FormFieldCommentEditDetail
