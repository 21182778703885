import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/components/action/export/zip/Zip.module.scss'
import { WppIconFileZip, WppListItem, WppTypography } from 'buildingBlocks'

interface IExportProps {
  handleZipExport: Function
}

/**
 * ZIP Export with all attachments
 * @param {object} props
 * @param {Function} props.handleZipExport
 */
const Zip: React.FC<IExportProps> = ({ handleZipExport }: IExportProps): React.ReactElement => {
  return (
    <WppListItem data-testid="btn-zip" onWppChangeListItem={() => handleZipExport()}>
      <p slot="left">
        <WppIconFileZip />
      </p>
      <p slot="label" className={styles.title}>
        <WppTypography tag="span" type="s-body">
          <Trans>action.export.zip_with_attachments</Trans>
        </WppTypography>
      </p>
    </WppListItem>
  )
}

export default Zip
