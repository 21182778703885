import { Page, Text, View, Document, Image, pdf } from '@react-pdf/renderer'
import { useOs } from '@wppopen/react'
import { Packer } from 'docx'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Doc from 'app/components/action/export/doc'
import PdfViewer from 'app/components/action/export/pdfViewer'
import Category from 'app/components/action/export/pdfViewer/category'
import pdfStyles from 'app/components/action/export/pdfViewer/styles'
import Zip from 'app/components/action/export/zip'
import { WppActionButton, WppIconExportFile, WppMenuContext } from 'buildingBlocks'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import useSpinner from 'hooks/useSpinner'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IFieldAttachment from 'interfaces/field/attachment/IFieldAttachment'
import { RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import { setErrorAlert, showAlert } from 'store/reducers/alertSlice'
import DocHelper from 'utils/file/DocHelper'
import FileHelper from 'utils/file/FileHelper'
import SharedHelper from 'utils/SharedHelper'

interface IExportProps {
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES, app: IApp | null) => void,
    displayMessage?: boolean,
  ) => void
  app: IApp
  projectQuestionnaireId: string
}

/**
 * Export component
 * @param {object} props
 * @param {Function} props.handleAppAction
 * @param {IApp} props.app
 * @param {string} props.projectQuestionnaireId
 */
const Export: React.FC<IExportProps> = ({
  handleAppAction,
  app,
  projectQuestionnaireId,
}: IExportProps): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const { setLoading } = useSpinner()
  const dispatch = useDispatch()
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const { t } = useTranslation()
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const [base64Logo, setBase64Logo] = useState<string>('')
  const logoOriginal = osContext.tenant.logoOriginal?.url ?? ''

  const generateDoc = async () => {
    const doc = DocHelper.createDoc(app, projectQuestionnaireId, t)
    return await Packer.toBlob(doc)
  }

  const generatePDF = async () => {
    return await pdf(
      <Document title={app.appName}>
        <Page size="A4" style={pdfStyles.page}>
          {!_.isEmpty(base64Logo) && (
            <Image src={`data:image/png;base64,${base64Logo}`} style={pdfStyles.tenantLogo} fixed />
          )}
          <Text style={pdfStyles.header}>{app.appName}</Text>
          <View style={pdfStyles.categoryContainer}>
            {app.categories.map((category: ICategory) => (
              <Category
                key={category.id}
                category={category}
                app={app}
                projectQuestionnaireId={projectQuestionnaireId}
              />
            ))}
          </View>
        </Page>
      </Document>,
    ).toBlob()
  }

  const handleDocExport = () => {
    handleAppAction(APP_ACTIONS.EXPORT, (message: TOAST_MESSAGE_TYPES) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR)) {
        generateDoc()
          .then((blob: Blob) => {
            saveAs(blob, `${app.appName}.docx`)
          })
          .catch(() => {
            dispatch(
              showAlert({
                message: t('action.export.document_error'),
                type: TOAST_MESSAGE_TYPES.ERROR,
                open: true,
              }),
            )
          })
      }
    })
  }

  const handlePdfExport = async () => {
    const blob = await generatePDF()
    const pdfUrl = URL.createObjectURL(blob)
    window.open(pdfUrl, '_blank')
  }

  const handleZipExport = async () => {
    handleAppAction(APP_ACTIONS.EXPORT, async (message: TOAST_MESSAGE_TYPES, app) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR) && app) {
        try {
          setLoading(true)
          const fileHelper = new FileHelper(osApi.getAccessToken())
          const attachments: { id: string; files: IFieldAttachment[] }[] = await fileHelper.getAllAttachments(
            app,
            appContext.tenantId,
          )

          const zip = new JSZip()
          const briefDoc = await generateDoc()
          const briefPdf = await generatePDF()

          const folder = zip.folder('Brief Exports - DOCX & PDF')
          folder?.file(`${app.appName}.docx`, briefDoc)
          folder?.file(`${app.appName}.pdf`, briefPdf)

          for (let attachment of attachments) {
            const folderName = attachment.id.slice(0, 100)
            const attachmentFolder = zip.folder(folderName)

            for (let file of attachment.files) {
              if (file.signedUrl) {
                const blob = await fetch(file.signedUrl).then(r => r.blob())
                attachmentFolder?.file(file.fileName, blob)
              }
            }
          }

          const blob = await zip.generateAsync({ type: 'blob' })
          saveAs(blob, app.appName)
          setLoading(false)
        } catch {
          dispatch(setErrorAlert(t('action.export.attachments_error')))
          setLoading(false)
        }
      }
    })
  }

  const setPDFLogo = useCallback(() => {
    if (logoOriginal) {
      setInitLoading(true)
      SharedHelper.getBase64Image(logoOriginal, (data: string) => {
        setBase64Logo(data)
        setInitLoading(false)
      })
    }
  }, [logoOriginal])

  useEffect(() => {
    setPDFLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoOriginal])

  return (
    <WppMenuContext>
      <WppActionButton style={{ minWidth: 100 }} loading={initLoading} slot="trigger-element">
        <WppIconExportFile slot="icon-start" size="s" />
        <Trans>action.export.title</Trans>
      </WppActionButton>
      <div>
        <PdfViewer handleAppAction={handleAppAction} handlePdfExport={handlePdfExport} />
        <Doc handleDocExport={handleDocExport} />
        <Zip handleZipExport={handleZipExport} />
      </div>
    </WppMenuContext>
  )
}

export default Export
