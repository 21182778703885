import { WppEmptyNoAccess, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'buildingBlocks/notAuthorized/NotAuthorized.module.scss'

interface INotAuthorizedProps {
  description: string
}

/**
 * Not authorized component
 * @param {object} props
 * @param {string} props.description
 */
const NotAuthorized: React.FC<INotAuthorizedProps> = ({ description }: INotAuthorizedProps): React.ReactElement => {
  return (
    <WppGrid container className={styles.errorContainer} rowSpacing={2}>
      <WppGrid item all={8} className={styles.error}>
        <WppEmptyNoAccess />
        <div className={styles.errorDescription}>
          <WppTypography type="m-strong" tag="p">
            <Trans>pages.not_authorized.title</Trans>
          </WppTypography>
          <WppTypography type="m-body">{description}</WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default NotAuthorized
