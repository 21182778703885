import { combineReducers, configureStore, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import { RESET_GLOBAL_STORE } from 'store/actions'
import AlertReducer from 'store/reducers/alertSlice'
import AppContextReducer from 'store/reducers/appContextSlice'
import ChangeLogReducer from 'store/reducers/changeLogSlice'
import LoaderReducer from 'store/reducers/loaderSlice'
import ProjectBriefReducer from 'store/reducers/projectBriefSlice'
import QuestionnaireReducer from 'store/reducers/questionnaireSlice'
import ReviewReducer from 'store/reducers/reviewSlice'

const combineReducer = combineReducers({
  appContext: AppContextReducer,
  projectBriefState: ProjectBriefReducer,
  questionnaireState: QuestionnaireReducer,
  alertState: AlertReducer,
  reviewState: ReviewReducer,
  changeLogState: ChangeLogReducer,
  loaderState: LoaderReducer,
})

const rootReducer = (state: any, action: PayloadAction<string>) => {
  if (_.isEqual(action.type, RESET_GLOBAL_STORE)) {
    state = {}
  }
  return combineReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
