import { WppSegmentedControl, WppSegmentedControlItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/review/sendToReview/SendToReview.module.scss'
import { WppButton, WppSideModal, WppSelect, WppListItem, WppTooltip } from 'buildingBlocks'
import { REVIEW_TYPES_DATA } from 'config/constants'
import { QUESTIONNAIRE_STATUS, REVIEW_TYPES } from 'config/enums'
import IProjectMember from 'interfaces/project/IProjectMember'
import IReviewerInfo from 'interfaces/review/IReviewerInfo'
import IReviewType from 'interfaces/review/IReviewType'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { setSendToReviewModal } from 'store/reducers/reviewSlice'
import QuestionnaireHelper from 'utils/questionnaire/QuestionnaireHelper'
import ReviewHelper from 'utils/review/ReviewHelper'
import { SelectChangeEventDetail, WppSegmentedControlItemCustomEvent, WppSelectCustomEvent } from 'utils/types'

/**
 * SendToReview Modal component
 */
const SendToReview: React.FC = (): React.ReactElement => {
  const { osApi } = useOs()
  const selectRef = useRef<HTMLWppSelectElement>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { selectedReviewers, isSendToReviewModalOpen, reviewers } = useSelector<RootState, IReviewState>(
    (state: RootState) => state.reviewState,
  )
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const [selectReviewers, setSelectReviewers] = useState<IProjectMember[]>([])

  const [reviewType, setReviewType] = useState<REVIEW_TYPES>(REVIEW_TYPES.ANY_REVIEW)
  const { questionnaire } = projectBriefState
  const { app, activeStep } = questionnaireState

  const Reviewers = reviewers.filter(
    (member: IProjectMember) => !_.isEqual(_.toLower(member.email), _.toLower(appContext.userEmail)),
  )

  useEffect(() => {
    if (isSendToReviewModalOpen) {
      setSelectReviewers(
        Reviewers.filter((member: IProjectMember) => {
          const reviewer: IReviewerInfo | undefined = selectedReviewers.find((reviewer: IReviewerInfo) => {
            return _.isEqual(_.toLower(member.email), _.toLower(reviewer.reviewerEmail))
          })
          return !_.isUndefined(reviewer)
        }),
      )

      if (questionnaire.approval) {
        let reviewT = _.find(REVIEW_TYPES_DATA, { key: questionnaire.approval.successConstraint })
        setReviewType(reviewT ? reviewT.key : REVIEW_TYPES.ANY_REVIEW)
      }
      selectRef?.current?.updateOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendToReviewModalOpen])

  if (
    !(
      (_.isNull(questionnaire.approval) ||
        (questionnaire.approval && _.isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED))) &&
      app?.isAppEditor &&
      app?.isProjectMember
    )
  )
    return <></>

  const handleModal = (isOpen: boolean) => {
    if (validateApp()) dispatch(setSendToReviewModal(isOpen))
  }

  const handleSendToReview = () => {
    ReviewHelper.handleSendToReview({
      accessToken: osApi.getAccessToken(),
      category: app.categories[activeStep - 1],
      approval: questionnaire.approval,
      dispatch,
      appContext,
      projectQuestionnaireId: _.toString(projectBriefState.questionnaire.projectQuestionnaireId),
      questionnaireState,
      reviewers: selectReviewers,
      reviewType,
      t,
    })
  }

  const validateApp = (): boolean => {
    return QuestionnaireHelper.validateApp(questionnaireState, dispatch)
  }

  const handleReviewType = (event: WppSegmentedControlItemCustomEvent<any>) => {
    setReviewType(event.detail.value)
  }

  return (
    <>
      <WppTooltip
        text={t('review.send_to_review.single_member')}
        component={
          <WppButton
            id="btn-send-to-review"
            disabled={_.isEmpty(Reviewers)}
            size="s"
            data-testid="btn-send-to-review"
            onClick={() => handleModal(true)}
          >
            <Trans>app.button.send_to_review</Trans>
          </WppButton>
        }
        showTooltip={_.isEmpty(Reviewers)}
      />
      {createPortal(
        <WppSideModal
          data-testid="side-modal"
          size="s"
          open={isSendToReviewModalOpen}
          onWppSideModalClose={() => handleModal(false)}
          onWppSideModalOpen={() => handleModal(true)}
          disableOutsideClick
        >
          <h3 slot="header">
            <Trans>review.send_to_review.title</Trans>
          </h3>
          <div slot="body" className={styles.sideModalBody}>
            <WppSelect
              data-testid="reviewer-change"
              onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                setSelectReviewers(event.detail.value)
              }}
              placeholder="ex. Nathalie, or james@consulting.com"
              value={selectReviewers}
              withSearch
              withFolder
              type="multiple"
              ref={selectRef}
            >
              {Reviewers.map((reviewer: IProjectMember) => (
                <WppListItem value={reviewer} key={reviewer.id}>
                  <p slot="label">
                    {reviewer.firstname} {reviewer.lastname}
                  </p>
                  <p slot="caption">{reviewer.email}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <div className={styles.reviewTypeContainer}>
              <WppTypography type="s-strong" tag="p">
                <Trans>review.type.title</Trans>
              </WppTypography>
              <WppSegmentedControl className={styles.reviewTypes} value={reviewType} size="s" width="100%">
                {REVIEW_TYPES_DATA.map((reviewT: IReviewType) => (
                  <WppSegmentedControlItem
                    onWppChangeSegmentedControlItem={handleReviewType}
                    key={reviewT.key}
                    value={reviewT.key}
                    data-testid="review-type"
                  >
                    <Trans>{reviewT.title}</Trans>
                  </WppSegmentedControlItem>
                ))}
              </WppSegmentedControl>
              <WppTypography type="xs-midi" tag="p">
                <Trans>{_.find(REVIEW_TYPES_DATA, { key: reviewType })?.description}</Trans>
              </WppTypography>
            </div>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppButton data-testid="btn-cancel" variant="secondary" onClick={() => handleModal(false)}>
              <Trans>app.button.cancel</Trans>
            </WppButton>
            <WppButton
              disabled={_.isEmpty(selectReviewers)}
              data-testid="btn-send-to-review-confirm"
              onClick={() => handleSendToReview()}
            >
              <Trans>app.button.send_for_review</Trans>
            </WppButton>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SendToReview
