import _ from 'lodash'

import { FIELD_TYPES } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'

export default class PDFHelper {
  /**
   * Return select value
   * @param {IField} field
   * @returns {any}
   */
  public static getSelectValue(field: IField): string[] {
    if (!field.value) {
      return ['']
    }
    let value = _.isArray(field.value) ? field.value : [field.value]
    value = value.map((val: any) => _.toString(val))
    return _.map(
      field.fieldConfig.options.filter((option: IOption) => !_.isEqual(value.indexOf(_.toString(option.id)), -1)),
      (option: IOption) => option.label,
    )
  }

  /**
   * Return radio value
   * @param {IField} field
   * @returns {any}
   */
  public static getRadioValue(field: IField): undefined | IOption {
    if (!field.value) {
      return undefined
    }
    const option: IOption | undefined = field.fieldConfig.options.find((option: IOption) =>
      _.isEqual(option.id, field.value),
    )

    return option
  }

  /**
   * Return Field value
   * @param {IField} field
   * @returns {any}
   */
  public static getFieldValue(field: IField): any {
    let value: any = ''

    switch (field.type) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.DATE_PICKER: {
        value = field.value ?? ''
        break
      }
      case FIELD_TYPES.DATE_RANGE_PICKER: {
        value = _.isEmpty(field.value) ? '' : field.value.join(' - ')
        break
      }
      case FIELD_TYPES.FILE_UPLOAD: {
        value = field.value ?? []
        break
      }
      case FIELD_TYPES.RADIO:
      case FIELD_TYPES.CHECKBOX: {
        value = field.value
        break
      }
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET: {
        value = this.getSelectValue(field)
        break
      }
      case FIELD_TYPES.CURRENCY: {
        value = `${field.value.currencyAmount} ${field.value.currencyType ?? ''}`
        break
      }
      case FIELD_TYPES.USER:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.AUTOCOMPLETE: {
        value = field.value.map((option: IOption) => {
          const subLabel = !option.subLabel ? '' : ` - ${option.subLabel}`
          return `${option.label}${subLabel}`
        })
      }
    }
    return value
  }
}
