import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/review/reviewers/Reviewers.module.scss'
import ReviewersList from 'app/components/review/reviewers/reviewersList'
import { WppIconEye, WppListItem, WppTypography } from 'buildingBlocks'
import { AppDispatch, RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { setViewStatusModal } from 'store/reducers/reviewSlice'

/**
 * Reviewers component
 */
const Reviewers: React.FC = (): React.ReactElement => {
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const dispatch = useDispatch<AppDispatch>()

  if (!questionnaire.approval) return <></>

  return (
    <>
      <WppListItem
        id="btn-view-status"
        data-testid="btn-view-status"
        onWppChangeListItem={() => dispatch(setViewStatusModal(true))}
      >
        <p slot="label" className={styles.menuItem}>
          <WppIconEye />
          <WppTypography tag="span" type="s-body">
            <Trans>action.menu.view_status</Trans>
          </WppTypography>
        </p>
      </WppListItem>
      <ReviewersList />
    </>
  )
}

export default Reviewers
