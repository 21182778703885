import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import { WppInput } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { InputChangeEventDetail, InputTypes } from 'utils/types'

interface IFormFieldTextInputProps {
  /** IField interface */
  field: IField
  /** Input type i.e number or Text*/
  inputType: InputTypes
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Element id */
  id?: string
}

/**
 * Create text input field
 * @param {object} props
 * @param {IField} props.field
 * @param {IField} props.id
 * @param {string} props.inputType
 * @param {Function} props.handleChange
 */
const FormFieldTextInput: React.FC<IFormFieldTextInputProps> = ({
  field,
  inputType,
  handleChange,
  id,
}: IFormFieldTextInputProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const { placeholder, text } = fieldConfig

  const handleInputChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    handleChange({
      field,
      value: event.detail.value,
    })
  }

  return (
    <WppInput
      placeholder={placeholder}
      value={value}
      onWppChange={handleInputChange}
      type={inputType}
      id={id ?? `textinput-${field.id}`}
      disabled={disabled}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
      name={text}
    />
  )
}

export default FormFieldTextInput
