import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Confirmation from 'app/components/confirmation'
import Questionnaires from 'app/components/questionnaires'
import Spinner from 'app/components/spinner/Spinner'
import { ApplicationError, NotAuthorized, WppButton, WppGrid, WppTypography } from 'buildingBlocks'
import { QUESTIONNAIRE_STATUS } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import styles from 'pages/projectBrief/ProjectBrief.module.scss'
import Questionnaire from 'pages/questionnaire/Questionnaire'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { setOpenModal, setParentQuestionnaireItem } from 'store/reducers/projectBriefSlice'
import ProjectBriefHelper from 'utils/projectBrief/ProjectBriefHelper'

/**
 * Project Brief Component
 */
const ProjectBrief: React.FC = (): React.ReactElement => {
  const { osApi } = useOs()
  const { t } = useTranslation()

  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const dispatch = useDispatch<AppDispatch>()

  const { initLoading, loading, openModal, showQuestionnaireSelection, disableFormSelection } = projectBriefState
  const { unauthorizedApp, unauthorizedQuestionnaire, serverError } = projectBriefState.error
  const { parentQuestionnaireItemId, parentQuestionnaires, questionnaireId, questionnaires, projectQuestionnaireId } =
    projectBriefState.questionnaire

  const accessToken: string = osApi.getAccessToken()

  const handleQuestionnaireSelection = (option: IOption) => {
    ProjectBriefHelper.handleQuestionnaireSelection(accessToken, appContext, option, projectBriefState, dispatch)
  }

  const handleParentQuestionnaireSelection = (option: IOption) => {
    dispatch(setParentQuestionnaireItem(option.id))
  }

  const onSaveProjectQuestionnaire = async () => {
    ProjectBriefHelper.onSaveProjectQuestionnaire(accessToken, appContext, projectBriefState, dispatch)
  }

  useEffect(() => {
    ProjectBriefHelper.initLoad(accessToken, projectBriefState, appContext, t, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (serverError) {
    return <ApplicationError />
  }

  if (unauthorizedApp) {
    return <NotAuthorized description={t('project_brief.unauthorized')} />
  }

  if (!_.isEmpty(unauthorizedQuestionnaire)) {
    return <NotAuthorized description={unauthorizedQuestionnaire} />
  }

  let isQuestionnaireSelection = _.isEqual(
    projectBriefState.questionnaire.approval?.status,
    QUESTIONNAIRE_STATUS.SUCCEEDED,
  )
    ? false
    : showQuestionnaireSelection

  return (
    <>
      <Confirmation
        title={t('project_brief.confirmation_modal.title')}
        body={t('project_brief.confirmation_modal.body')}
        isOpen={openModal}
        handlePrimaryAction={onSaveProjectQuestionnaire}
        handleSecondaryAction={() => dispatch(setOpenModal(false))}
      />
      {(initLoading || loading) && <Spinner />}
      {!initLoading && isQuestionnaireSelection && (
        <WppGrid container className={styles.layout} rowSpacing={2}>
          <WppGrid item all={24} className={styles.layoutHeader}>
            <WppTypography tag="h1" type="2xl-heading">
              <Trans>project_brief.title</Trans>
            </WppTypography>
          </WppGrid>
          <WppGrid className={styles.layoutForm} item all={24}>
            <WppGrid container className={styles.form}>
              <WppGrid item all={8}>
                <WppTypography className={styles.name} tag="h2" type="xl-heading">
                  <Trans>project_brief.form.title</Trans>
                </WppTypography>
                <p className={styles.description}>
                  <Trans>project_brief.form.desc</Trans>
                </p>
                <Questionnaires
                  parentQuestionnaires={parentQuestionnaires}
                  questionnaires={questionnaires}
                  handleParentQuestionnaireSelection={handleParentQuestionnaireSelection}
                  handleQuestionnaireSelection={handleQuestionnaireSelection}
                  parentQuestionnaireItemId={parentQuestionnaireItemId}
                  questionnaireId={questionnaireId}
                  disableFormSelection={disableFormSelection}
                />
                <WppButton
                  disabled={!questionnaireId || (!_.isEmpty(parentQuestionnaires) && !parentQuestionnaireItemId)}
                  id="btn-save"
                  onClick={() => dispatch(setOpenModal(true))}
                >
                  <Trans>app.button.save</Trans>
                </WppButton>
              </WppGrid>
            </WppGrid>
          </WppGrid>
        </WppGrid>
      )}
      {!initLoading && !_.isNull(projectQuestionnaireId) && !_.isNull(questionnaireId) && !isQuestionnaireSelection && (
        <Questionnaire />
      )}
    </>
  )
}

export default ProjectBrief
