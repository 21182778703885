import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const defaultAlertState: boolean = false

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: defaultAlertState,
  reducers: {
    setLoader: (state: boolean, action: PayloadAction<boolean>): boolean => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoader } = loaderSlice.actions

export default loaderSlice.reducer
