import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/review/cancelReview/CancelReview.module.scss'
import { WppButton, WppIconReject, WppListItem, WppModal, WppTooltip, WppTypography } from 'buildingBlocks'
import { PROJECT_ROLES, QUESTIONNAIRE_STATUS } from 'config/enums'
import IProjectMember from 'interfaces/project/IProjectMember'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import ReviewHelper from 'utils/review/ReviewHelper'

/**
 * Cancel Review component
 */
const CancelReview: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const { osApi } = useOs()

  const dispatch = useDispatch<AppDispatch>()
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { reviewers } = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const [open, setOpen] = useState(false)

  const isAuthorized: boolean = useCallback(() => {
    const member: IProjectMember | undefined = reviewers.find((member: IProjectMember) =>
      _.isEqual(_.toLower(member.email), _.toLower(appContext.userEmail)),
    )
    return _.isEqual(member?.role, PROJECT_ROLES.OWNER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.userEmail])()

  if (!questionnaire.approval) return <></>

  if (!(_.isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.PENDING) && isAuthorized)) {
    return <></>
  }

  const handleModal = () => setOpen((prevState: boolean) => !prevState)

  const handleCancelReview = () => {
    ReviewHelper.handleCancelReview({
      accessToken: osApi.getAccessToken(),
      dispatch,
      projectQuestionnaireId: _.toString(questionnaire.projectQuestionnaireId),
      approval: questionnaire.approval,
      questionnaireState,
      tenantId: appContext.tenantId,
      appInstanceId: appContext.appInstanceId,
      t,
      appContext,
    })
  }

  return (
    <>
      <WppTooltip
        text={t('review.cancel_review.unauthorized')}
        component={
          <WppListItem
            id="btn-cancel-review"
            disabled={!isAuthorized}
            data-testid="btn-cancel-review"
            onWppChangeListItem={handleModal}
          >
            <p slot="label" className={styles.menuItem}>
              <WppIconReject />
              <WppTypography tag="span" type="s-body">
                <Trans>action.menu.cancel_review</Trans>
              </WppTypography>
            </p>
          </WppListItem>
        }
        showTooltip={!isAuthorized}
      />
      {createPortal(
        <WppModal open={open}>
          <h3 slot="header">
            <Trans>review.cancel_review.title</Trans>
          </h3>
          <p slot="body">
            <Trans>review.cancel_review.body</Trans>
          </p>
          <div slot="actions" className={styles.actions}>
            <WppButton size="s" id="btn-no" data-testid="btn-no" variant="secondary" onClick={handleModal}>
              <Trans>app.button.no</Trans>
            </WppButton>
            <WppButton
              variant="destructive"
              id="btn-yes"
              data-testid="btn-yes"
              size="s"
              onClick={() => {
                handleCancelReview()
                handleModal()
              }}
            >
              <Trans>app.button.yes</Trans>
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </>
  )
}

export default CancelReview
