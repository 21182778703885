import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import RemoveFormField from 'app/components/form/formBuilder/formField/actions/Remove'
import styles from 'app/components/form/formField/formFieldLabel/FormFieldLabel.module.scss'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import { WppTypography, WppIconAvailableCheckmark } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IFormFieldLabelProps {
  /** IField object */
  field: IField
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** If the field is first element */
  isFirstField: boolean
  /** Repeatable Field Id */
  repeatableFieldId?: string
}

/**
 * Create label for the field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 * @param {boolean} props.isFirstField
 * @param {boolean} props.repeatableFieldId
 */
const FormFieldLabel: React.FC<IFormFieldLabelProps> = ({
  field,
  handleChange,
  isFirstField,
  repeatableFieldId,
}: IFormFieldLabelProps): React.ReactElement => {
  const { fieldConfig, id, disabled, isRequired, isCompleted } = field
  const { description, tooltip, helpModal, text } = fieldConfig

  return (
    <>
      {!_.isEmpty(text) && (
        <div className={styles.container}>
          <WppTypography className={styles.label} aria-disabled={disabled} id={`label-${id}`} type="s-strong">
            <div className={styles.labelContainer}>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
                id={`btn-label-${id}`}
                data-testid={`btn-label-${id}`}
                className={styles.btnLabel}
                aria-hidden={true}
              />
              {!disabled && !isRequired && (
                <WppTypography type="s-body" tag="p" className={styles.optional}>
                  (<Trans>app.title.optional</Trans>)
                </WppTypography>
              )}
              <div className={styles.tooltip}>
                <FormFieldTooltip helpModal={helpModal} tooltip={tooltip} />
              </div>
              {isCompleted && (
                <div className={styles.checkmark}>
                  <WppIconAvailableCheckmark backgroundColor="var(--wpp-text-color-success)" />
                </div>
              )}
            </div>
          </WppTypography>
          {!isFirstField && !disabled && (
            <RemoveFormField field={field} handleChange={handleChange} repeatableFieldId={repeatableFieldId} />
          )}
        </div>
      )}
      {description && !_.isEmpty(description) && (
        <WppTypography className={styles.description} type="s-body">
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldLabel
